import Modal from "../../../node_modules/bootstrap/js/dist/modal";


window.ShowCancelBookingModal = function () {
    ControlCancellationModalElementExist(function () {
        cancellationModal.show();
    });
};

window.InitCancelBookingModalEvents = function () {
    let cancellationModalElement = document.getElementById("cancellationModal");
    cancellationModalElement.addEventListener("shown.bs.modal",
        function () {
            if ($(".modal-backdrop").length > 1) {
                $(".modal-backdrop").not(":first").remove();
            }
        });
};

window.ControlCancellationModalElementExist = function (callback) {
    let cancellationModalElement = document.getElementById("cancellationModal");
    if (cancellationModalElement) {
        callback();
        return;
    }

    $.ajax({
        type: 'GET',
        url: '/Start/CancelBookingModal',
        success: function (cancellationModalHtml) {
            $('body').append(cancellationModalHtml);
            let cancellationModalElement = document.getElementById("cancellationModal");

            window.cancellationModal = new Modal(cancellationModalElement,
                {
                    focus: true,
                    keyboard: true,
                    backdrop: true
                });

            InitCancelBookingModalEvents();

            callback();
        }
    });
};



(function () {
    $(document).on("click",
        ".show-cancel-booking-modal",
        function () {
            ShowCancelBookingModal();
        });

    var hash = window.location.hash.substr(1);

    if (hash.toLowerCase() === "avboka") {
        ShowCancelBookingModal();
    }
})();
